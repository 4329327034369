/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button, Fullmap, FullmapWrap, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Nemovisti na prodej"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--40" style={{"backgroundColor":"#f5f8fd"}} name={"ght4l56i4kt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--black);\">Nemovitosti na prodej</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":35,"backgroundColor":"rgba(255,255,255,1)","paddingBottom":105}} name={"vmftil57cn8"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37752/7fc46e1a965144f7956f8d2b0874724b_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--20 lh--12" style={{"marginTop":21}} content={"Rodinný dům ve Velké Hraštici – Perfektní bydlení blízko Prahy<br>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"Krásný rodinný dům z roku 2017 v obci Velká Hraštice, jen kousek od Prahy, čeká na svého nového majitele. Ideální pro rodinné bydlení v klidné lokalitě. Přijďte se přesvědčit na vlastní oči!<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 fs--16" style={{"marginTop":15}} content={"Zobrazit detail"} use={"page"} href={"/detail"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/4bc7aef3fd61447c80d59fc20ba296d0_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/4bc7aef3fd61447c80d59fc20ba296d0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37752/4bc7aef3fd61447c80d59fc20ba296d0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37752/4bc7aef3fd61447c80d59fc20ba296d0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37752/4bc7aef3fd61447c80d59fc20ba296d0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37752/4bc7aef3fd61447c80d59fc20ba296d0_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--20 lh--12" style={{"marginTop":21}} content={"Prostorný rodinný dům ve Velké Dobré – moderní technologie a komfort na dosah<br>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"V srdci Velké Dobré, pouhých 15 minut od obchodního centra Zličín a s rychlým napojením na dálnici D6, se nachází tato unikátní novostavba rodinného domu 5+kk, která splní očekávání i těch nejnáročnějších klientů.<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 fs--16" style={{"marginTop":15}} content={"Zobrazit detail"} use={"page"} href={"/detail-Kopie-4g3crru0ece"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/e6f3a8ec3aac40478138902fa213178f_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/e6f3a8ec3aac40478138902fa213178f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37752/e6f3a8ec3aac40478138902fa213178f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37752/e6f3a8ec3aac40478138902fa213178f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37752/e6f3a8ec3aac40478138902fa213178f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37752/e6f3a8ec3aac40478138902fa213178f_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--20 lh--12" style={{"marginTop":21}} content={"Prostorný rodinný dům s možností investičního využití v klidné části Děčína – Krásný Studenec<br>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"Skvěle řešený rodinný dům s možností samostatného podkrovního bytu nabízí ideální bydlení i příležitost k pronájmu. Klidná lokalita, blízkost přírody a veškerá občanská vybavenost na dosah.<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 fs--16" style={{"marginTop":15}} content={"Zobrazit detail"} use={"page"} href={"/detail-Kopie-4g3crru0ece-Kopie-7au3p9g1xd9"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/2e2234d47db641b297d0bee98ac2af88_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/2e2234d47db641b297d0bee98ac2af88_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/2e2234d47db641b297d0bee98ac2af88_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/2e2234d47db641b297d0bee98ac2af88_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--20" style={{"marginTop":21}} content={"<span style=\"color: var(--color-blend--15);\">Prodej bytu 3+1 78 Náměstí osvoboditelů, Praha - Radotín<br></span><span style=\"color: rgb(129, 25, 25);\">(Prodáno)</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-blend--50);\">Úspěšně prodáno a předáno! Nemovitost v Radotíně již dělá radost novým majitelům. Celý proces prodeje proběhl podle plánu a musel být sladěn s přesnými termíny, protože prodávající se stěhovali do zahraničí. Díky správnému ocenění, strategii a kvalitní prezentaci se byt prodal přesně dle představ, což prodávajícím zajistilo dostatek času na přípravy k novému životu v zahraničí.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/f26caf9dcaf945cca86a6fad60913b29_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/f26caf9dcaf945cca86a6fad60913b29_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/f26caf9dcaf945cca86a6fad60913b29_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/f26caf9dcaf945cca86a6fad60913b29_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--20" style={{"marginTop":21}} content={"<span style=\"color: var(--color-blend--15);\">Prodej rodinného domu 4+kk Mokrovraty<br></span><span style=\"color: rgb(129, 25, 25);\">(Prodáno)</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"Prodaný domeček v Mokrovratech našel své nové majitele! Tento útulný dům s dispozicí 4+kk vzbudil obrovský zájem, a už po prvních prohlídkách jsme měli tři vážné zájemce. Přestože bych rád vyhověl všem, úspěch mohl slavit jen jeden. Mokrovraty jsou malebná vesnice v blízkosti dálnice D4, odkud se do Prahy dostanete pohodlně do 20 minut. Pro ty, kteří hledají klidné bydlení s rychlým spojením do města, je tato lokalita ideální volbou.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/175bf129573246a3827a4fc6bf0d10ea_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/175bf129573246a3827a4fc6bf0d10ea_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/175bf129573246a3827a4fc6bf0d10ea_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/175bf129573246a3827a4fc6bf0d10ea_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--20" style={{"marginTop":21}} content={"<span style=\"color: var(--color-blend--15);\">Prodej řadového domu 4+kk Praha -Horní Počernice<br></span><span style=\"color: rgb(129, 25, 25);\">(Prodáno)</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"Prodávat nemovitosti, ve kterých bych si sám dokázal představit bydlení, je pro mě vždy radost – a tento krásný řadový dům v Praze byl přesně takovým případem. Moderní dům s dispozicí 4+kk splňuje i ty nejnáročnější požadavky na bydlení: nabízí garáž, terasu, zahradu a další skvělé prvky, které už teď naplno využívají noví majitelé.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/b999e9420221498f830fdd5f8c8443ac_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/b999e9420221498f830fdd5f8c8443ac_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/b999e9420221498f830fdd5f8c8443ac_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/b999e9420221498f830fdd5f8c8443ac_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--20" style={{"marginTop":21}} content={"<span style=\"color: var(--color-blend--15);\">Prodej bytu 4+kk Praha - Žižkov<br></span><span style=\"color: rgb(129, 25, 25);\">(Prodáno)</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"Krásný byt na Žižkově, o který byl velký zájem, našel své nové majitele. Nachází se v atraktivní pěší dostupnosti k žižkovské věži a díky dispozici 4+kk nabízí i možnost dvougeneračního bydlení nebo řadu úprav podle přání nových majitelů. Skutečnou výjimečnost bytu ale podtrhují dva prostorné balkony, každý na opačné straně bytu, s krásnými výhledy na Vítkov. Taková nabídka je prostě neodolatelná.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pl--06 pr--06" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37752/19f71135e2ed4148bdc8ad93ca0e6a15_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/37752/19f71135e2ed4148bdc8ad93ca0e6a15_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/19f71135e2ed4148bdc8ad93ca0e6a15_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/19f71135e2ed4148bdc8ad93ca0e6a15_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--20" style={{"marginTop":21}} content={"<span style=\"color: var(--color-blend--15);\">Prodej bytu 3+kk Chrášťany<br></span><span style=\"color: rgb(129, 25, 25);\">(Prodáno)</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":467}} content={"Krásný byt v příjemné zástavbě v Chrášťanech už dělá radost svým novým majitelům. Tento vzdušný byt o dispozici 3+kk nabízí nejen pohodlné bydlení, ale i spoustu úložného prostoru, včetně prostorného sklepa o výměře 15 m². O dostatek místa pro vše potřebné tu rozhodně není nouze – ideální volba pro ty, kdo hledají komfort a praktičnost v klidné lokalitě.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"enigi1bl9om"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}